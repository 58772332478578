var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 border-info rounded-lg shadow-take-photo-itineraries"
  }, [_c('div', {
    staticClass: "section-table pax-table-template-columns"
  }, [_c('div', {
    staticClass: "fw-700 d-flex align-items-center p-4px-16px"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.no')) + " ")]), _c('div', {
    staticClass: "fw-700 d-flex align-items-center p-4px-16px"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.passenger')) + " ")]), _c('div', {
    staticClass: "fw-700 d-flex align-items-center p-4px-16px"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.additionalServices')) + " ")]), _vm._l(_vm.paxs, function (pax, paxIndex) {
    var _pax$passenger, _pax$passenger2, _pax$passenger3, _pax$passenger4;
    return [_c('div', {
      key: "no-".concat(paxIndex),
      staticClass: "p-4px-16px d-flex align-items-center w-100 border-t"
    }, [_c('div', [_vm._v(" " + _vm._s(String(paxIndex + 1).padStart(2, "0")) + " ")])]), _c('div', {
      key: "pax-".concat(paxIndex),
      staticClass: "p-4px-16px d-flex align-items-center w-100 border-t"
    }, [_c('div', {
      staticClass: "fw-700 w-100"
    }, [pax !== null && pax !== void 0 && (_pax$passenger = pax.passenger) !== null && _pax$passenger !== void 0 && _pax$passenger.paxType && pax !== null && pax !== void 0 && (_pax$passenger2 = pax.passenger) !== null && _pax$passenger2 !== void 0 && _pax$passenger2.title ? [_vm._v(" (" + _vm._s(pax.passenger.title && _vm.$te("reservation.titleOptions.".concat(pax.passenger.paxType === 'INFANT' ? 'CHILD' : pax.passenger.paxType, ".").concat(pax.passenger.title)) ? _vm.$t("reservation.titleOptions.".concat(pax.passenger.paxType === 'INFANT' ? 'CHILD' : pax.passenger.paxType, ".").concat(pax.passenger.title)) : pax.passenger.title) + ") ")] : _vm._e(), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(" " + _vm._s(_vm.getLocaleFullName(pax.passenger)) + " ")]), (_pax$passenger3 = pax.passenger) !== null && _pax$passenger3 !== void 0 && _pax$passenger3.travelFlyer ? _c('span', {
      staticClass: "font-italic"
    }, [_vm._v(" (" + _vm._s((_pax$passenger4 = pax.passenger) === null || _pax$passenger4 === void 0 ? void 0 : _pax$passenger4.travelFlyer) + ") ")]) : _vm._e()], 2)]), _c('div', {
      key: "bag-".concat(paxIndex),
      staticClass: "p-4px-16px d-flex align-items-center w-100 gap-1 border-t"
    }, [_c('div', {
      staticClass: "flex-1"
    }, [pax.bags && pax.bags.length ? [_vm._l(pax.bags, function (bag, bagIndex) {
      return [bag.xBagName && bag.trip ? _c('div', {
        key: "BAG-".concat(bagIndex, "-").concat(bag.segmentIds[0], "-").concat(bag.paxId, "-").concat(bag.id)
      }, [_vm._v(" " + _vm._s(bag.trip) + ": " + _vm._s(bag.xBagName) + " ")]) : _vm._e()];
    })] : _vm._e(), pax.others && pax.others.length ? [_vm._l(pax.others, function (other, otherIndex) {
      return [other.trip && other.otherName ? _c('div', {
        key: "OTHER-".concat(otherIndex, "-").concat(other.segmentIds[0], "-").concat(other.paxId, "-").concat(other.serviceSubCode, "-").concat(other.id)
      }, [_vm._v(" " + _vm._s(other.trip) + ": " + _vm._s(other.otherName) + " ")]) : _vm._e()];
    })] : _vm._e(), pax.seat && pax.seat.length ? [_vm._l(pax.seat, function (seat, seatIndex) {
      return [seat.seatName ? _c('div', {
        key: "seat-".concat(seatIndex, "-").concat(seat.segmentIds[0], "-").concat(seat.paxId, "-").concat(seat.id, "-").concat(seat.pdcSeat)
      }, [_vm._v(" " + _vm._s(seat.trip) + ": " + _vm._s(seat.seatName) + " ")]) : _vm._e()];
    })] : _vm._e()], 2)])];
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }