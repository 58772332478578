<!-- eslint-disable vue/no-mutating-props -->
<template lang="">
  <div class="w-100 border-info rounded-lg shadow-take-photo-itineraries">
    <div class="section-table pax-table-template-columns">
      <div class="fw-700 d-flex align-items-center p-4px-16px">
        {{ $t('ticket.paxTable.no') }}
      </div>
      <div class="fw-700 d-flex align-items-center p-4px-16px">
        {{ $t('ticket.paxTable.passenger') }}
      </div>

      <div class="fw-700 d-flex align-items-center p-4px-16px">
        {{ $t('ticket.paxTable.additionalServices') }}
      </div>

      <template v-for="(pax, paxIndex) in paxs">
        <div
          :key="`no-${paxIndex}`"
          class="p-4px-16px d-flex align-items-center w-100 border-t"
        >
          <div>
            {{ String(paxIndex + 1).padStart(2, "0") }}
          </div>
        </div>
        <div
          :key="`pax-${paxIndex}`"
          class="p-4px-16px d-flex align-items-center w-100 border-t"
        >
          <div class="fw-700 w-100">
            <template v-if="pax?.passenger?.paxType && pax?.passenger?.title">
              <!-- title INFANT đổi thành bé trai/gái https://discord.com/channels/1054696448110903327/1329715495997280318 -->
              ({{ pax.passenger.title && $te(`reservation.titleOptions.${pax.passenger.paxType === 'INFANT' ? 'CHILD' : pax.passenger.paxType}.${pax.passenger.title}`)
                ? $t(`reservation.titleOptions.${pax.passenger.paxType === 'INFANT' ? 'CHILD' : pax.passenger.paxType}.${pax.passenger.title}`)
                : pax.passenger.title }})
            </template>
            <span class="fw-700">
              {{ getLocaleFullName(pax.passenger) }}
            </span>
            <span
              v-if="pax.passenger?.travelFlyer"
              class="font-italic"
            >
              ({{ pax.passenger?.travelFlyer }})
            </span>
          </div>
        </div>

        <div
          :key="`bag-${paxIndex}`"
          class="p-4px-16px d-flex align-items-center w-100 gap-1 border-t"
        >
          <div class="flex-1">
            <template v-if="pax.bags && pax.bags.length">
              <template v-for="(bag, bagIndex) in pax.bags">
                <div
                  v-if="bag.xBagName && bag.trip"
                  :key="`BAG-${bagIndex}-${bag.segmentIds[0]}-${bag.paxId}-${bag.id}`"
                >
                  {{ bag.trip }}:
                  {{ bag.xBagName }}
                </div>
              </template>
            </template>

            <template v-if="pax.others && pax.others.length">
              <template v-for="(other, otherIndex) in pax.others">
                <div
                  v-if="other.trip && other.otherName"
                  :key="`OTHER-${otherIndex}-${other.segmentIds[0]}-${other.paxId}-${other.serviceSubCode}-${other.id}`"
                >
                  {{ other.trip }}:
                  {{ other.otherName }}
                </div>
              </template>
            </template>

            <template v-if="pax.seat && pax.seat.length">
              <template v-for="(seat, seatIndex) in pax.seat">
                <div
                  v-if="seat.seatName"
                  :key="`seat-${seatIndex}-${seat.segmentIds[0]}-${seat.paxId}-${seat.id}-${seat.pdcSeat}`"
                >
                  {{ seat.trip }}:
                  {{ seat.seatName }}
                </div>
              </template>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty'

import { getLocaleFullName } from '@/views/apps/invoices/useInvoice'

export default {
  props: {
    paxs: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      getLocaleFullName,
      isEmpty,
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep {
  .section-table {
    display: grid;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    overflow: hidden;

    &.pax-table-template-columns {
      grid-template-columns: 6% 44% 50%;
    }

    .border-t {
      border-top: 1px solid #E6E6E6;
    }
  }
}
</style>
